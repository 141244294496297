import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import "./index.scss";

const YoutubeList = ({ data }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const cid = match.params.cid;

  return (
    <ul className="youtube_list">
      {data &&
        data.map((v, i) => {
          return (
            <li key={i}>
              <Link
                to={
                  !window.location.pathname.includes("youtube")
                    ? `/hospital/${cid}/youtube${history.location.search}`
                    : `/hospital/${cid}/youtube/detail/${v.id}${history.location.search}`
                }
              >
                <div className="video" style={{ background: `url(${v.thumbnails})` }} />
                <p className="title">{v.title}</p>
              </Link>
            </li>
          );
        })}
    </ul>
  );
};

export default YoutubeList;
