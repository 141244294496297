import styled, { css } from "styled-components";

const colorStyles = {
  primary: css`
    color: #ffffff;
    background: #897dff;
  `,
  secondary: css`
    color: #897dff;
    background: #f4f3ff;
  `,
};

const Button = styled.div`
  text-align: center;
  & > * + * {
    margin-left: 8px;
  }

  width: 100%;
  padding: 13px 0px;
  border-radius: 8px;

  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  cursor: pointer;

  ${({ color }) => colorStyles[color || "primary"]};
`;

export default Button;
