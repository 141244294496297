import axios from "axios";
import { _openModal } from "./modal";

export const _get_hospital_info = (cid, smsSearchId, customerId) => dispatch => {
  axios
    .get(process.env.REACT_APP_HOSPITAL_API_URL + `/hospitals/${cid}`, {
      params: { smsSearchId: smsSearchId, customerId: customerId },
    })
    .then(response => {
      dispatch({
        type: "HOSPITAL_LOAD_SUCCESS",
        cid: cid,
        data: response.data.data,
      });
    })
    .catch(error => {
      dispatch(
        _openModal({
          type: "alert",
          title: "안내",
          msg: error.response.data.message,
        })
      );
    });
};
