import React from "react";
import styled from "styled-components";

const BannerBox = styled.div`
  & > * + img,
  & > * + * img {
    margin-top: 12px;
  }
  img {
    width: 100%;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;

  color: #484760;
`;

const Banner = ({ title, image, deeplink }) => {
  return (
    <BannerBox>
      {title && <Title>{title}</Title>}
      {image &&
        (deeplink ? (
          <a href={deeplink}>
            <img src={image} alt="" />
          </a>
        ) : (
          <img src={image} alt="" />
        ))}
    </BannerBox>
  );
};

export default Banner;
