// default
export const _hiddenLoading = () => dispatch => {
  document.body.style.overflow = null;
  dispatch({
    type: "HIDDEN_LOADING",
  });
};

export const _showLoading = () => dispatch => {
  document.body.style.overflow = "hidden";
  dispatch({
    type: "SHOW_LOADING",
  });
};
