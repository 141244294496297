import React from "react";
import styled from "styled-components";
import Button from "./Button";

const Box = styled.div`
  & > * + * {
    margin-top: 24px;
  }

  margin-top: 24px;
  padding: 40px 18px;
  background: #f4f3ff;
  border-radius: 8px;

  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #484760;
`;

const Share = ({ showToast }) => {
  const copy = () => {
    if (!document.queryCommandSupported("copy")) {
      return alert("복사 기능이 지원되지 않는 브라우저입니다.");
    }

    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    showToast();
  };

  return (
    <Box>
      <span>
        다양한 할인 소식을
        <br />
        친구들에게 공유해보세요!
      </span>
      <Button onClick={copy}>공유하기</Button>
    </Box>
  );
};

export default Share;
