import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { serialize } from "object-to-formdata";
import Select from "react-select";
import { _openModal } from "../../actions/modal";

const options = [
  { value: "naver.com", label: "naver.com" },
  { value: "hanmail.net", label: "hanmail.net" },
  { value: "gmail.com", label: "gmail.com" },
  { value: "nate.com", label: "nate.com" },
  { value: "", label: "직접입력" },
];

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const [, setCookie] = useCookies(["cookie-name"]);
  const [email, setEmail] = useState("");
  const [emailDomain, setEmailDomain] = useState("");
  const [emailCustomDomain, setEmailCustomDomain] = useState("");
  const [pw, setPw] = useState("");

  useEffect(() => {
    setEmailCustomDomain("");
  }, [emailDomain]);

  const callLogin = () => {
    let obj = {
      email: email + "@" + (emailCustomDomain || emailDomain.value),
      password: pw,
      browser: 1,
    };

    axios
      .post(process.env.REACT_APP_HOSPITAL_API_URL + `/auth/login`, serialize(obj))
      .then(function (response) {
        setCookie("BABI_TOKEN", response.data.token, { path: "/", expires: new Date("2030-12-31") });
        history.push(`${window.location.pathname.replace("/login", "")}${history.location.search}`);
        window.location.reload();
      })
      .catch(function (error) {
        dispatch(
          _openModal({
            type: "alert",
            title: "안내",
            msg: error.response.data.message,
          })
        );
      });
  };

  return (
    <>
      <div className="login">
        <div>
          <div className="logo" />
          <p className="sub_text">아름다움을 위한 바른 선택</p>
          <h1>대한민국 1등 성형 정보앱, 바비톡</h1>

          <div className="wrap_form">
            <div className="form">
              <div className="row">
                <p>이메일</p>
                <div className="email_input">
                  <input value={email} onChange={e => setEmail(e.target.value)} placeholder="이메일을 입력해주세요" />
                  <span>@</span>
                  {emailDomain.value === "" && (
                    <input
                      className="domain_input"
                      value={emailCustomDomain}
                      onChange={e => setEmailCustomDomain(e.target.value)}
                      placeholder="직접입력"
                    />
                  )}
                  <Select
                    className="react-select-container"
                    value={emailDomain}
                    onChange={v => setEmailDomain(v)}
                    options={options}
                    placeholder={"도메인을 선택해주세요"}
                    isSearchable={false}
                    classNamePrefix
                  />
                </div>
              </div>
              <div className="row">
                <p>비밀번호</p>
                <input
                  value={pw}
                  onChange={e => setPw(e.target.value)}
                  type="password"
                  placeholder="●●●●●●●●"
                  autoComplete="new-password"
                />
                <p className="help">이메일, 비밀번호 찾기는 바비톡 앱에서만 가능합니다.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="btn_bottom_login">
          바비톡이 처음이신가요?{" "}
          <Link to={`${window.location.pathname.replace("/login", "")}/join${history.location.search}`}>회원가입</Link>
        </div>
        <div className="btn_bottom">
          <button onClick={() => callLogin()} disabled={!email || !pw || !(emailDomain.value || emailCustomDomain)}>
            이메일 로그인
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
