import axios from "axios";
import { _openModal } from "./modal";

export const _get_category = () => dispatch => {
  axios
    .get(process.env.REACT_APP_HOSPITAL_API_URL + `/reviews/meta/categories`)
    .then(response => {
      dispatch({
        type: "REVIEW_CATEGORY_LOAD_SUCCESS",
        categoryList: response.data.categories,
      });
    })
    .catch(error => {
      console.log(error);
    });
};

export const _get_review_list = (type, id, category, sort, token, onLogoutAction) => dispatch => {
  dispatch({
    type: "REVIEW_LIST_LOAD_LOADING",
    loading: true,
  });
  axios
    .get(process.env.REACT_APP_HOSPITAL_API_URL + `/${type}/${id}/reviews`, {
      params: { category, sort: sort.value },
      headers: { "Authorization": token },
    })
    .then(response => {
      dispatch({
        type: "REVIEW_LIST_LOAD_SUCCESS",
        data: response.data.data,
        pagination: response.data.meta.pagination,
        category: category,
        sort: sort,
      });
    })
    .catch(error => {
      console.log(error);
      dispatch(
        _openModal({
          type: "alert",
          title: "안내",
          msg: error.response.data.message,
        })
      );
      onLogoutAction();
    })
    .finally(() => {
      dispatch({
        type: "REVIEW_LIST_LOAD_LOADING",
        loading: false,
      });
    });
};

export const _get_review_list_more = (apiUrl, category, sort, token) => dispatch => {
  dispatch({
    type: "REVIEW_LIST_LOAD_LOADING",
    loading: true,
  });
  axios
    .get(apiUrl, {
      params: { category, sort: sort.value },
      headers: { "Authorization": token },
    })
    .then(response => {
      dispatch({
        type: "REVIEW_LIST_MORE_LOAD_SUCCESS",
        data: response.data.data,
        pagination: response.data.meta.pagination,
        category: category,
        sort: sort,
      });
    })
    .catch(error => {
      console.log(error);
      dispatch(
        _openModal({
          type: "alert",
          title: "안내",
          msg: error.response.data.message,
        })
      );
    })
    .finally(() => {
      dispatch({
        type: "REVIEW_LIST_LOAD_LOADING",
        loading: false,
      });
    });
};

export const _reset_review_list = () => dispatch => {
  dispatch({
    type: "RESET_REVIEW_LIST",
  });
};
