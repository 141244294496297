// default
export const _closeModal = () => dispatch => {
  document.body.style.overflow = null;
  dispatch({
    type: "CLOSE_MODAL",
  });
};

export const _openModal = data => dispatch => {
  document.body.style.overflow = "hidden";
  dispatch({
    type: "OPEN_MODAL",
    onHide: _closeModal,
    data: data,
  });
};

// image
export const _closeImageModal = () => dispatch => {
  document.body.style.overflow = null;
  dispatch({
    type: "CLOSE_IMAGE_MODAL",
  });
};

export const _openImageModal = data => dispatch => {
  document.body.style.overflow = "hidden";
  dispatch({
    type: "OPEN_IMAGE_MODAL",
    onHide: _closeImageModal,
    data: data,
  });
};
