export const getParams = name => {
  let params = window.location.search.substr(window.location.search.indexOf("?") + 1);
  params = params.split("&");
  for (let i = 0; i < params.length; i++) {
    let temp = params[i].split("=");
    if (temp[0] === name) {
      return temp[1];
    }
  }
};
