import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { Link } from "react-router-dom";
import Select from "react-select";
import Layout from "../../component/common/layout";
import { _openModal, _closeModal } from "../../actions/modal";

const ageOptions = () => {
  let arr = [];
  let year = new Date().getFullYear();
  for (let i = 2006; i >= 1957; i--) {
    arr.push({ value: i, label: `${i}년생 (${year - i + 1})세` });
  }
  return arr;
};

const doaminOptions = [
  { value: "naver.com", label: "naver.com" },
  { value: "hanmail.net", label: "hanmail.net" },
  { value: "gmail.com", label: "gmail.com" },
  { value: "nate.com", label: "nate.com" },
  { value: "", label: "직접입력" },
];

const Join = ({ history }) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [emailDomain, setEmailDomain] = useState("");
  const [emailCustomDomain, setEmailCustomDomain] = useState("");
  const [pw, setPw] = useState("");
  const [pw2, setPw2] = useState("");
  const [showPw, setShowPw] = useState(false);
  const [showPw2, setShowPw2] = useState(false);

  const [nickname, setNickname] = useState("");
  const [gender, setGender] = useState(0);
  const [birth, setBirth] = useState("");

  const [termCheck1, setTermCheck1] = useState(false);
  const [termCheck2, setTermCheck2] = useState(false);
  const [termCheck3, setTermCheck3] = useState(false);
  const [termEvent, setTermEvent] = useState(false);
  const [event_push, setEventPush] = useState(false);
  const [event_sms, setEventSMS] = useState(false);
  const [event_email, setEventEmail] = useState(false);

  useEffect(() => {
    setEmailCustomDomain("");
  }, [emailDomain]);

  useEffect(() => {
    setEventPush(false);
    setEventSMS(false);
    setEventEmail(false);
  }, [termEvent]);

  const toggleAllTerms = () => {
    if (termCheck1 && termCheck2 && termCheck3) {
      setTermCheck1(false);
      setTermCheck2(false);
      setTermCheck3(false);
    } else {
      setTermCheck1(true);
      setTermCheck2(true);
      setTermCheck3(true);
    }
  };

  const nextPage = () => {
    dispatch(
      _openModal({
        type: "confirm",
        title: "이메일 재확인",
        buttonText: ["아니요", "예"],
        msg: (
          <>
            입력하신 이메일은 <u>{email + "@" + (emailCustomDomain || emailDomain.value)}</u>입니다. <br />
            이메일은 로그인 및 계정 분실 시 활용됩니다.
          </>
        ),
        onAction: () => {
          dispatch(_closeModal());
          setStep(2);
        },
      })
    );
  };

  const checkPassword = v => {
    if (v.length === 0) return false;

    const num = v.search(/[0-9]/g);
    const eng = v.search(/[a-z]/gi);
    const spe = v.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

    if (v.length < 9 || v.length > 20) {
      return "9자리 ~ 20자리 이내로 입력해주세요.";
    } else if (v.search(/\s/) !== -1) {
      return "비밀번호는 공백 없이 입력해주세요.";
    } else if (num < 0 || eng < 0 || spe < 0) {
      return "영문,숫자, 특수문자를 혼합하여 입력해주세요.";
    } else {
      return true;
    }
  };

  const checkEmail = () => {
    let data = {
      email: email + "@" + (emailCustomDomain || emailDomain.value),
    };

    axios
      .post(process.env.REACT_APP_HOSPITAL_API_URL + `/auth/check/account`, serialize(data))
      .then(function (response) {
        nextPage();
      })
      .catch(function (error) {
        dispatch(
          _openModal({
            type: "alert",
            title: "안내",
            msg: error.response.data.message,
          })
        );
      });
  };

  const checkValue = () => {
    let msg;
    if (!nickname) msg = "닉네임을 입력해주세요";
    else if (!gender) msg = "성별을 선택해주세요";
    else if (!birth) msg = "나이를 선택해주세요";
    else if (!termCheck1 || !termCheck2 || !termCheck3) msg = "필수동의사항을 확인해주세요";
    else return true;

    dispatch(
      _openModal({
        type: "alert",
        title: "안내",
        msg: msg,
        onAction: () => {
          dispatch(_closeModal());
        },
      })
    );

    return false;
  };

  const callJoin = () => {
    if (!checkValue()) return;
    let data = {
      email: email + "@" + (emailCustomDomain || emailDomain.value),
      password: pw,

      name: nickname,
      gender: gender,
      birth: birth.value,

      event_push: Number(event_push),
      event_sms: Number(event_sms),
      event_email: Number(event_email),
    };

    axios
      .post(process.env.REACT_APP_HOSPITAL_API_URL + `/auth/new_join`, serialize(data))
      .then(function (response) {
        dispatch(
          _openModal({
            type: "alert",
            title: "안내",
            msg: "회원가입이 완료되었습니다",
          })
        );
        history.push(`${window.location.pathname.replace("/join", "/login")}${history.location.search}`);
      })
      .catch(function (error) {
        dispatch(
          _openModal({
            type: "alert",
            title: "안내",
            msg: error.response.data.message,
          })
        );
      });
  };

  return (
    <Layout
      buttons={["close"]}
      onAction={e => history.push(`${window.location.pathname.replace("/join", "")}/login${history.location.search}`)}
      containerClassName="join"
    >
      <div className="wrap_form">
        <h2>회원가입</h2>
        {step === 1 && (
          <>
            <div className="form">
              <div className="row">
                <p>이메일</p>
                <div className="email_input">
                  <input value={email} onChange={e => setEmail(e.target.value)} placeholder="이메일을 입력해주세요" />
                  <span>@</span>
                  {emailDomain.value === "" && (
                    <input
                      className="domain_input"
                      value={emailCustomDomain}
                      onChange={e => setEmailCustomDomain(e.target.value)}
                      placeholder="직접입력"
                    />
                  )}
                  <Select
                    className="react-select-container"
                    value={emailDomain}
                    onChange={v => setEmailDomain(v)}
                    options={doaminOptions}
                    placeholder={"도메인을 선택해주세요"}
                    isSearchable={false}
                    classNamePrefix
                  />
                </div>
                <p className="help">유효하지 않은 이메일 주소일 경우, 서비스 이용에 제한을 받을 수 있습니다.</p>
              </div>
              <div className="row">
                <p>
                  비밀번호<span onClick={() => setShowPw(!showPw)}>표시</span>
                </p>
                <input
                  value={pw}
                  onChange={e => setPw(e.target.value)}
                  type={showPw ? "text" : "password"}
                  placeholder="비밀번호를 입력해주세요"
                  autoComplete="off"
                />
              </div>
              <div className="row">
                <p>
                  비밀번호 확인<span onClick={() => setShowPw2(!showPw2)}>표시</span>
                </p>
                <input
                  value={pw2}
                  onChange={e => setPw2(e.target.value)}
                  type={showPw2 ? "text" : "password"}
                  placeholder="비밀번호를 한번 더 입력해주세요"
                  maxLength="20"
                />
                {pw !== pw2 && pw.length > 0 && pw2.length > 0 ? (
                  <p className="help red">비밀번호가 일치하지 않습니다.</p>
                ) : (
                  typeof checkPassword(pw) === "string" && <p className="help red">{checkPassword(pw)}</p>
                )}
              </div>
            </div>

            <div className="btn_bottom_login">
              이미 바비톡 회원이신가요?{" "}
              <Link to={`${window.location.pathname.replace("/join", "")}/login`}>로그인</Link>
            </div>
            <div className="btn_bottom">
              <button
                onClick={() => checkEmail()}
                disabled={
                  !(email && (emailCustomDomain || emailDomain.value) && pw === pw2 && checkPassword(pw) === true)
                }
              >
                다음
              </button>
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <div className="form">
              <div className="row">
                <p>닉네임</p>
                <div className="email_input">
                  <input
                    value={nickname}
                    onChange={e => setNickname(e.target.value)}
                    placeholder="닉네임을 입력해주세요"
                  />
                  {nickname !== "" && <button className="btn_clear_input" onClick={() => setNickname("")}></button>}
                </div>
              </div>
              <div className="row">
                <p>성별</p>
                <ul className="check_box">
                  <li className={`short ${gender === 1 ? "select" : ""}`} onClick={() => setGender(1)}>
                    남자
                  </li>
                  <li className={`short ${gender === 2 ? "select" : ""}`} onClick={() => setGender(2)}>
                    여자
                  </li>
                </ul>
              </div>
              <div className="row">
                <p>나이</p>
                <Select
                  className="react-select-container"
                  value={birth}
                  onChange={v => setBirth(v)}
                  options={ageOptions()}
                  placeholder={"나이를 선택해주세요"}
                  isSearchable={false}
                  classNamePrefix
                />
              </div>
              <div className="row">
                <p>이용자 동의</p>
                <ul className="term check_box">
                  <li
                    className={termCheck1 && termCheck2 && termCheck3 ? "select" : ""}
                    onClick={() => toggleAllTerms()}
                  >
                    아래 내용 모두 동의
                  </li>
                  <li className={termCheck1 ? "select" : ""} onClick={() => setTermCheck1(!termCheck1)}>
                    (필수)개인정보처리방침
                    <a target="_blank" rel="noopener noreferrer" href="http://www.babitalk.com/policy/terms">
                      상세보기
                    </a>
                  </li>
                  <li className={termCheck2 ? "select" : ""} onClick={() => setTermCheck2(!termCheck2)}>
                    (필수)개인정보 수집·이용
                    <a target="_blank" rel="noopener noreferrer" href="http://www.babitalk.com/policy/privacy_collect">
                      상세보기
                    </a>
                  </li>
                  <li className={termCheck3 ? "select" : ""} onClick={() => setTermCheck3(!termCheck3)}>
                    (필수)개인정보 처리위탁
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.babitalk.com/policy/privacy_commission"
                    >
                      상세보기
                    </a>
                  </li>
                  <li className={termEvent ? "select" : ""} onClick={() => setTermEvent(!termEvent)}>
                    (선택)이벤트/예약 알림 수신
                  </li>
                  <li
                    className={`short ${!termEvent ? "block" : event_push ? "select" : ""}`}
                    onClick={() => termEvent && setEventPush(!event_push)}
                  >
                    푸시알림
                  </li>
                  <li
                    className={`short ${!termEvent ? "block" : event_sms ? "select" : ""}`}
                    onClick={() => termEvent && setEventSMS(!event_sms)}
                  >
                    SMS
                  </li>
                  <li
                    className={`short ${!termEvent ? "block" : event_email ? "select" : ""}`}
                    onClick={() => setEventEmail(!event_email)}
                  >
                    이메일
                  </li>
                </ul>
              </div>
            </div>
            <div className="btn_bottom">
              <button onClick={() => callJoin()}>완료</button>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Join;
