import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { _openModal } from "../actions/modal";

const useCheckToken = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies, , removeCookie] = useCookies(["cookie-name"]);

  const checkToken = useCallback(() => {
    const logout = () => {
      removeCookie("BABI_TOKEN", { path: "/" });
      window.location.reload();
    };

    axios
      .get(process.env.REACT_APP_HOSPITAL_API_URL + `/auth/check/token`, {
        headers: { Authorization: cookies["BABI_TOKEN"] },
      })
      .catch(function (error) {
        dispatch(
          _openModal({
            type: "alert",
            title: "안내",
            msg: `중복 아이디 접속이 확인되었습니다. \n다시 로그인해주세요`,
            onAction: () => {
              logout();
            },
          })
        );
      });
  }, [cookies, dispatch, removeCookie]);

  useEffect(() => {
    cookies["BABI_TOKEN"] && checkToken();
    history.listen(() => {
      cookies["BABI_TOKEN"] && checkToken();
    });
  }, [checkToken, cookies, history]);

  return cookies["BABI_TOKEN"] && checkToken();
};

export default useCheckToken;
