import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _get_hospital_info } from "../actions/hospital";
import { getParams } from "../utils/getParams";
import { useRouteMatch } from "react-router-dom";

const useHospital = cid => {
  const dispatch = useDispatch();
  const hospital = useSelector(state => state.hospital_reducer);
  const match = useRouteMatch();

  useEffect(() => {
    // cid 변경 했을때, store에 병원 데이터 없을 경우에만 api 재호출
    if (match.params.cid !== cid || !hospital.cid) {
      dispatch(_get_hospital_info(cid, getParams("smsSearchId"), getParams("customerId")));
    }
  }, [cid, dispatch, hospital.cid, match.params.cid]);

  return hospital;
};

export default useHospital;
