import { combineReducers } from "redux";
import { modal_reducer, image_modal_reducer } from "./modal";
import { hospital_reducer, review_reducer } from "./hospital";
import { loading_reducer } from "./loading";

const reducer = combineReducers({
  modal_reducer,
  image_modal_reducer,
  hospital_reducer,
  review_reducer,
  loading_reducer,
});

export default reducer;
