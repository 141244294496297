import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { _openModal } from "actions/modal";

import Layout from "component/exhibition/Layout";
import Content from "component/exhibition/Content";
import Banner from "component/exhibition/Banner";
import Button from "component/exhibition/Button";
import Events from "component/exhibition/Events";
import Share from "component/exhibition/Share";
import Toast from "component/exhibition/Toast";
import { _hiddenLoading, _showLoading } from "actions/loading";

const List = ({ match }) => {
  const dispatch = useDispatch();
  const loading_reducer = useSelector(store => store.loading_reducer);
  const id = match.params.id;

  const [eventList, setEventList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [exhibition, setExhibition] = useState({});
  const [recommendExhibition, setRecommendExhibition] = useState({});

  const [showToast, setShowToast] = useState(false);

  const callAPI = useCallback(
    (page = 1) => {
      if (loading_reducer.show) return;
      dispatch(_showLoading());
      axios
        .get(`${process.env.REACT_APP_API_URL}/special_exhibitions/web/${id}?page=${page}`, {
          headers: { "x-babi-version": "Android/6.0.0" },
        })
        .then(response => {
          const { data } = response;
          setExhibition({
            image: data.image,
            deeplink: data.deeplink,
          });

          if (parseInt(page) > 1) {
            setEventList([...eventList, ...data.data]);
          } else {
            setEventList(data.data);
          }

          setPagination(data.meta.pagination);
          setRecommendExhibition(data?.recommended_exhibition || {});

          dispatch(_hiddenLoading());
        })
        .catch(error => {
          dispatch(_hiddenLoading());
          dispatch(
            _openModal({
              type: "alert",
              title: "안내",
              msg: "종료된 기획전입니다.",
            })
          );
        });
    },
    [eventList, id, dispatch, loading_reducer]
  );

  useEffect(() => {
    callAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!exhibition?.image) return <></>;
  return (
    <>
      <Layout>
        <Toast show={showToast} setShow={setShowToast}>
          링크가 복사되었어요
        </Toast>
        <Banner image={exhibition.image} />
        <Content>
          {/* 
          <Button onClick={() => (window.location.href = exhibition.deeplink)}>
            <span>이벤트 보러가기</span>
            <img src={require("assets/images/icon/icon-arrow-right.svg").default} alt="" />
          </Button>
          */}
          <Events eventList={eventList} />
          {pagination.total_page > pagination.current_page && (
            <Button color="secondary" onClick={() => callAPI(pagination.current_page + 1)}>
              더보기
            </Button>
          )}

          {recommendExhibition?.image && <Banner title="함께 볼만한 기획전" {...recommendExhibition} />}

          <Share link={exhibition.deeplink} showToast={() => setShowToast(true)} />
        </Content>
      </Layout>
    </>
  );
};

export default List;
