import React, { useState, useEffect, useCallback } from "react";
import { Route } from "react-router-dom";
import axios from "axios";
import useHospital from "../../hooks/useHospital";
import Layout from "../../component/common/layout";
import EventList from "../../component/hospital/eventList";
import EventDetail from "./EventDetail";
import { useEventListener } from "../../utils/use_event_listener";

const Event = ({ match }) => {
  const cid = match.params.cid;
  const hospital = useHospital(cid).data;
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState({ current_page: 0, total_page: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const getEventList = useCallback(id => {
    axios
      .get(process.env.REACT_APP_HOSPITAL_API_URL + `/hospitals/${id}/events`)
      .then(function (response) {
        setData(response.data.data);
        setPagination(response.data.meta.pagination);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const getMoreList = useCallback(() => {
    if (!pagination?.links.next) return;
    if (isLoading) return;

    setIsLoading(true);
    axios
      .get(pagination.links.next)
      .then(response => {
        setData([...data, ...response.data.data]);
        setPagination(response.data.meta.pagination);
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false));
  }, [data, pagination, isLoading]);

  const handleScroll = e => {
    if (window.location.pathname.includes("detail")) return;

    let offset = (document.body.scrollHeight - document.body.clientHeight) * 0.8;
    if (offset < window.scrollY && !isLoading) {
      getMoreList();
    }
  };

  useEffect(() => {
    if (!hospital) return;
    window.scroll(0, 0);
    getEventList(hospital.id);
  }, [hospital, getEventList]);

  useEventListener("scroll", handleScroll, window);

  return (
    <>
      <Route
        exact
        path={["/hospital/:cid", "/hospital/:cid/event"]}
        render={() => (
          <Layout title="이벤트" containerClassName="event" Login>
            <EventList data={data} />
          </Layout>
        )}
      />
      <Route exact path="/hospital/:cid/event/detail/:id" render={() => <EventDetail />} />
    </>
  );
};

export default Event;
