export function hospital_reducer(state = {}, action) {
  switch (action.type) {
    case "HOSPITAL_LOAD_SUCCESS":
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
}

export function review_reducer(state = {}, action) {
  switch (action.type) {
    case "REVIEW_LIST_LOAD_LOADING":
      return {
        ...state,
        ...action,
      };

    case "REVIEW_LIST_LOAD_SUCCESS":
      return {
        ...state,
        ...action,
      };

    case "REVIEW_LIST_MORE_LOAD_SUCCESS":
      return {
        ...state,
        ...action,
        data: [...state.data, ...action.data],
      };

    case "REVIEW_CATEGORY_LOAD_SUCCESS":
      return {
        ...state,
        ...action,
      };

    case "RESET_REVIEW_LIST":
      return {};

    default:
      return state;
  }
}

export function event_reducer(state = {}, action) {
  switch (action.type) {
    case "EVENT_LIST_LOAD_SUCCESS":
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
}

export function doctor_reducer(state = {}, action) {
  switch (action.type) {
    case "DOCTOR_LIST_LOAD_SUCCESS":
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
}

export function youtube_reducer(state = {}, action) {
  switch (action.type) {
    case "YOUTUBE_LIST_LOAD_SUCCESS":
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
}
