const initialState = {
  show: false,
};

export function modal_reducer(state = initialState, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { show: true, ...action };
    case "CLOSE_MODAL":
      return { initialState };
    default:
      return state;
  }
}
export function image_modal_reducer(state = initialState, action) {
  switch (action.type) {
    case "OPEN_IMAGE_MODAL":
      return { show: true, ...action };
    case "CLOSE_IMAGE_MODAL":
      return { initialState };
    default:
      return state;
  }
}
