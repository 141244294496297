import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import axios from "axios";
import Layout from "../../component/common/layout";

const EventDetail = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const [detail, setDetail] = useState(null);
  const { cid, id } = match.params;

  const getEventDetail = useCallback(() => {
    axios
      .get(process.env.REACT_APP_HOSPITAL_API_URL + `/events/${id}`)
      .then(function (response) {
        setDetail(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    window.scroll(0, 0);
    getEventDetail();
  }, [getEventDetail]);

  if (!detail) return false;
  return (
    <Layout title="이벤트" containerClassName="full event_detail" Login>
      <div className="event_into">
        <h1>{detail.name}</h1>
        <p className="price">
          {detail.discount_price < detail.price && (
            <span className="percentage">{Number.parseInt(100 - (detail.discount_price / detail.price) * 100)}%</span>
          )}
          <span className="discount_price">{detail.discount_price / 10000}만원</span>
          {detail.discount_price < detail.price && <span className="price">{detail.price / 10000}만원</span>}
        </p>
        <p className="date">
          이벤트 기간{" "}
          <span>
            {detail.start_date.replace(/-/g, ".")} - {detail.end_date.replace(/-/g, ".")}
          </span>
        </p>
        <p className="desc">{detail.description}</p>
      </div>
      <img src={detail.image} alt="이벤트 상세 이미지" />

      <div className="btn_bottom">
        <button
          onClick={e =>
            window.open(
              `${process.env.REACT_APP_UNO_URL}/event_apply/index.html${history.location.search || "?"}&eid=${
                detail.id
              }&cid=${cid}`
            )
          }
        >
          이벤트 신청
        </button>
      </div>
    </Layout>
  );
};

export default EventDetail;
