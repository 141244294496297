import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import axios from "axios";
import { _openImageModal, _openModal } from "../../actions/modal";
import Layout from "../../component/common/layout";

const ReviewDetail = ({ match, history }) => {
  const dispatch = useDispatch();
  const { cid, id } = match.params;

  const [cookies, , removeCookie] = useCookies(["cookie-name"]);
  const [review, setReview] = useState(null);

  const getReview = useCallback(() => {
    const logout = () => {
      // 로그아웃시 초기화 되어야하는 url params
      const url = new URL(window.location);
      url.searchParams.delete("doctor_id");

      removeCookie("BABI_TOKEN", { path: "/" });
      history.push(`/hospital/${cid}${url.search}`);
    };

    axios
      .get(process.env.REACT_APP_HOSPITAL_API_URL + `/reviews/${id}`, {
        headers: { Authorization: cookies["BABI_TOKEN"] },
      })
      .then(function (response) {
        setReview(response.data.data);
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          dispatch(
            _openModal({
              type: "alert",
              title: "안내",
              msg: error.response.data.message,
            })
          );
          logout();
        }
      });
  }, [cookies, dispatch, id, cid, history, removeCookie]);

  useEffect(() => {
    window.scroll(0, 0);
    getReview();
  }, [getReview]);

  if (!review) return false;
  return (
    <Layout title={"성형톡"} containerClassName="review review_detail" Login>
      <div className="wrap_review_list">
        <div>
          <div className="user">
            <div className="img" style={{ background: `url(${review.user.data.small_image})` }} />
            <div className="info">
              <p className="name">{review.user.data.name}</p>
              <p className="create_time">{review.created_at}</p>
            </div>
          </div>
          <div
            className={`img_info grid_${review.images.data.length < 7 ? review.images.data.length : "6 more"}`}
            onClick={e => {
              history.push(`${window.location.pathname}${window.location.search}`);
              dispatch(
                _openImageModal({
                  isReview: true,
                  index: [...e.target.parentElement.childNodes].indexOf(e.target),
                  images: review.images.data,
                })
              );
            }}
          >
            {review.images.data.map((v, i) => (
              <div
                key={i}
                className={`img ${review.surgery ? "after" : "before"}`}
                style={{ background: `url(${v.small_image})` }}
              />
            ))}
          </div>
          <div className="text_info">
            <p className="subcategories">{review.subcategories.toString()}</p>
            <p className="surgery_at">{review.surgery_time && `수술/시술시기 ${review.surgery_time}`}</p>
            <p className="desc">{review.text}</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReviewDetail;
