import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import axios from "axios";
import useHospital from "../../hooks/useHospital";
import Layout from "../../component/common/layout";
import DoctorList from "../../component/hospital/doctorList";
import DoctorDetail from "./DoctorDetail";

const Doctor = ({ match }) => {
  const cid = match.params.cid;
  const hospital = useHospital(cid).data;
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!hospital) return;
    window.scroll(0, 0);
    getDoctorList(hospital.id);
  }, [hospital]);

  const getDoctorList = id => {
    axios
      .get(process.env.REACT_APP_HOSPITAL_API_URL + `/hospitals/${id}/doctors`)
      .then(function (response) {
        setData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Route
        exact
        path={["/hospital/:cid", "/hospital/:cid/doctor"]}
        render={() => (
          <Layout title="의료진소개" containerClassName="doctor" Login>
            <DoctorList data={data} />
          </Layout>
        )}
      />
      <Route exact path="/hospital/:cid/doctor/detail/:id" component={DoctorDetail} />
    </>
  );
};

export default Doctor;
