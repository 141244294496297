import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./index.scss";

const ImageModal = ({ data, onHide }) => {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const swiperRef = useRef(null);
  const history = useHistory();
  const [index, setIndex] = useState(data.index);

  // 페이지 이동시 이미지 팝업 닫기
  useEffect(() => {
    history.listen(() => dispatch(onHide()));
  }, [history, dispatch, onHide]);

  const swiperConfig = {
    arrows: false,
    initialSlide: data.index,
    infinite: true,
    speed: 300,
    afterChange: i => {
      setIndex(i);
    },
  };

  return (
    <div ref={modalRef} className="wrap_image_modal">
      <div className="header_img_slider">
        {data.isReview ? (
          <p>
            {data.images[index].surgery ? "후" : "전"} ({index + 1} of {data.images.length})
          </p>
        ) : (
          <p>
            {index + 1} of {data.images.length}
          </p>
        )}
        <button className="btn_close" onClick={() => history.goBack()}></button>
      </div>
      <Slider className="img_slider" ref={swiperRef} {...swiperConfig}>
        {data &&
          data.images.map((v, i) => (
            <div className="slide" key={i}>
              <img src={v.image || v} alt="병원 이미지" />
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default ImageModal;
