import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { TweenMax, Linear } from "gsap";
import Alert from "./Alert";
import Confirm from "./Confirm";
import "./index.scss";

const Modal = ({ data, onHide }) => {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const [modalAction, setModalAction] = useState("show");
  const [component, setComponent] = useState();
  const defaultProps = { ...data, onHide: string => setModalAction(string) };

  useEffect(() => {
    if (modalAction === "show") {
      document.body.style.overflow = "hidden";
      TweenMax.to(modalRef.current, 0.1, {
        autoAlpha: 1,
        ease: Linear.easeInOut,
        onComplete: () => {},
      });
    } else if (modalAction === "hide" || "cancel") {
      if (modalAction === "hide" && data.onAction) return data.onAction();
      if (modalAction === "cancel" && data.onCancelAction) return data.onCancelAction();
      TweenMax.to(modalRef.current, 0.1, {
        autoAlpha: 0,
        ease: Linear.easeInOut,
        onComplete: () => {
          dispatch(onHide());
        },
      });
    }
  }, [modalAction, data, dispatch, onHide]);

  useEffect(() => {
    switch (data.type) {
      case "alert":
        setComponent(<Alert {...defaultProps} />);
        break;
      case "confirm":
        setComponent(<Confirm {...defaultProps} />);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.type]);

  return (
    <div ref={modalRef} className="wrap_modal">
      {component}
    </div>
  );
};

export default Modal;
