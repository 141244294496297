import React, { useEffect, useState } from "react";
import Layout from "../../component/common/layout";
import { useRouteMatch } from "react-router-dom";

const YoutubeDetail = ({ data }) => {
  const match = useRouteMatch();
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    window.scroll(0, 0);
    if (!data.length || !data.filter(v => v.id === match.params.id).length) {
      let pathname = window.location.pathname;
      window.location.pathname = pathname.slice(0, pathname.indexOf("detail") - 1);
    } else {
      setDetail(data.filter(v => v.id === match.params.id));
    }
  }, [data, match.params.id]);

  if (!detail || detail.length === 0) return false;
  return (
    <Layout title={detail[0].title} containerClassName="youtube_detail" Login>
      <div className="video">
        <iframe
          title={detail[0].title}
          frameBorder="0"
          allowFullScreen="1"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          src={`https://www.youtube.com/embed/${detail[0].video_id}`}
          id="widget2"
        ></iframe>
      </div>
      <p className="title">{detail[0].title}</p>
      <p className="desc">{detail[0].description}</p>
    </Layout>
  );
};

export default YoutubeDetail;
