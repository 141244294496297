import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import "./index.scss";

const EventList = ({ data, region }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const cid = match.params.cid;

  return (
    <ul className="event_list">
      {data &&
        data.map((v, i) => {
          return (
            <li key={i}>
              <Link to={`/hospital/${cid}/event/detail/${v.event_id}${history.location.search}`}>
                <div className={`event_thumb ${v.is_hot ? "hot" : ""}`} style={{ background: `url(${v.image})` }}></div>
                <div className="event_info">
                  <p className="name">{v.name}</p>
                  <p className="desc">{v.description}</p>
                  <p className="client">
                    {v.region} • {v.hospital_name}
                  </p>
                  <p className="price">
                    <span className="discount_price">{v.discount_price / 10000}만원</span>
                    {v.discount_price < v.price && (
                      <>
                        <span className="percentage">{Number.parseInt(100 - (v.discount_price / v.price) * 100)}%</span>
                        <span className="price">{v.price / 10000}만원</span>
                      </>
                    )}
                  </p>
                </div>
              </Link>
            </li>
          );
        })}
    </ul>
  );
};

export default EventList;
