import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import { numberWithCommas } from "../../../utils/number";
import "./index.scss";

const DoctorList = ({ data, isSingle }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const cid = match.params.cid;

  if (!isSingle) {
    return (
      <ul className="doctor_list">
        {data &&
          data.map((v, i) => {
            return (
              <li key={i}>
                <Link to={`/hospital/${cid}/doctor/detail/${v.id}${history.location.search}`}>
                  <div
                    className={`doctor_img ${!v.profile_video ? "none" : ""}`}
                    style={{ background: `url(${v.profile_photo})` }}
                  ></div>
                  <div className="doctor_info">
                    <p className="name">
                      {v.name} {v.position} <span>전문의</span>
                    </p>
                    <p className="desc">{v.hospital.data.name}</p>
                    <p className="major">{v.subject.toString()}</p>
                    <p className="count">
                      조회 {numberWithCommas(v.view_count)} 성형톡 {numberWithCommas(v.review_count)}
                    </p>
                    <div className="icons">
                      {v.video_count > 0 && <span className="one_min" />}
                      {v.ask_badge && <span className="one_min" />}
                    </div>
                  </div>
                </Link>
              </li>
            );
          })}
      </ul>
    );
  } else {
    return (
      <div className="doctor_profile">
        <div className="doctor_img none" style={{ background: `url(${data.profile_photo})` }}></div>
        <div className="doctor_info">
          <p className="name">
            {data.name} {data.position}
          </p>
          {data.specialties && <p className="desc">{data.specialties} 전문의</p>}
          <p className="desc">경력 {new Date().getFullYear() - Number(data.since.slice(0, 4)) + 1}년</p>
          <p className="count">조회 {numberWithCommas(data.review_count)}</p>
        </div>
      </div>
    );
  }
};

export default DoctorList;
