import React from "react";

const Alert = ({ title, msg, onHide }) => {
  return (
    <div className="modal modal-alert">
      <div className="content">
        <h2 className="title">{title}</h2>
        {msg || "메세지를 입력해주세요."}
      </div>
      <div className="wrap-btn">
        <button
          onClick={() => {
            onHide("hide");
          }}
        >
          확인
        </button>
      </div>
    </div>
  );
};

export default Alert;
