import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const ToastBox = styled.div`
  position: fixed;
  width: 100%;

  bottom: 14px;

  max-width: inherit;
  box-sizing: border-box;
  padding: 0px 30px;

  display: none;
  z-index: 999;

  &.slide-up {
    bottom: 14px;
    display: block;

    animation: slideup 0.5s;
    -moz-animation: slideup 0.5s; /* Firefox */
    -webkit-animation: slideup 0.5s; /* Safari and Chrome */
    -o-animation: slideup 0.5s; /* Opera */

    /* fade in */
    @keyframes slideup {
      from {
        bottom: -56px;
      }
      to {
        bottom: 14px;
      }
    }
    @-moz-keyframes slideup {
      /* Firefox */
      from {
        bottom: -56px;
      }
      to {
        bottom: 14px;
      }
    }
    @-webkit-keyframes slideup {
      /* Safari and Chrome */
      from {
        bottom: -56px;
      }
      to {
        bottom: 14px;
      }
    }
    @-o-keyframes slideup {
      /* Opera */
      from {
        bottom: -56px;
      }
      to {
        bottom: 14px;
      }
    }
  }

  &.fade-out {
    display: block;
    animation: fadeout 0.5s;
    -moz-animation: fadeout 0.5s; /* Firefox */
    -webkit-animation: fadeout 0.5s; /* Safari and Chrome */
    -o-animation: fadeout 0.5s; /* Opera */

    /* fade in */
    @keyframes fadeout {
      from {
        opacity: 0.9;
      }
      to {
        opacity: 0;
        display: none;
      }
    }
    @-moz-keyframes fadeout {
      /* Firefox */
      from {
        opacity: 0.9;
      }
      to {
        opacity: 0;
        display: none;
      }
    }
    @-webkit-keyframes fadeout {
      /* Safari and Chrome */
      from {
        opacity: 0.9;
      }
      to {
        opacity: 0;
        display: none;
      }
    }
    @-o-keyframes fadeout {
      /* Opera */
      from {
        opacity: 0.9;
      }
      to {
        opacity: 0;
        display: none;
      }
    }
  }
`;

const ToastContent = styled.div`
  background: #373649;
  opacity: 0.9;
  border-radius: 8px;
  padding: 15px 24px 17px;

  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
`;

const Toast = ({ show, setShow, children }) => {
  const [effect, setEffect] = useState("");

  useEffect(() => {
    if (show) {
      setEffect("slide-up");
      setTimeout(() => {
        setEffect("fade-out");
      }, 2000);

      setTimeout(() => {
        setEffect("");
        setShow(false);
      }, 2500);
    }
  }, [show, setShow]);

  return (
    show && (
      <ToastBox className={effect}>
        <ToastContent>{children}</ToastContent>
      </ToastBox>
    )
  );
};

export default Toast;
