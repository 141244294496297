import styled from "styled-components";

const Content = styled.div`
  & > * + * {
    margin-top: 16px;
  }

  margin: 16px 14px;
  text-aling: center;
`;

export default Content;
