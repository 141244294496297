import React from "react";

import useOrientationChange from "../../../hooks/useOrientationChange";
import useCheckToken from "../../../hooks/useCheckToken";

const Common = () => {
  useOrientationChange();
  useCheckToken();

  return <></>;
};

export default Common;
