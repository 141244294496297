import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { _reset_review_list } from "../../actions/review";
import { _openImageModal, _openModal, _closeModal } from "../../actions/modal";
import useHospital from "../../hooks/useHospital";
import { useEventListener } from "../../utils/use_event_listener";
import { numberWithCommas } from "../../utils/number";

import EventList from "../../component/hospital/eventList";
import DoctorList from "../../component/hospital/doctorList";
import YoutubeList from "../../component/hospital/youtubeList";

const Hospital = ({ match, history }) => {
  const dispatch = useDispatch();
  const cid = match.params.cid;
  const hospital = useHospital(match.params.cid).data;
  const [cookies, , removeCookie] = useCookies(["cookie-name"]);

  const headerRef = useRef(null);
  const mainImgRef = useRef(null);

  useEffect(() => {
    dispatch(_reset_review_list());
  }, [dispatch]);

  const logout = () => {
    removeCookie("BABI_TOKEN", { path: "/" });
    window.location.reload();
  };

  const showLoginConfirm = () => {
    dispatch(
      _openModal({
        type: "confirm",
        title: "안내",
        buttonText: ["취소", "로그인"],
        msg: "해당 병원의 성형톡은 바비톡 계정으로 로그인 시 확인이 가능합니다.",
        onAction: () => {
          dispatch(_closeModal());
          history.push(`${window.location.pathname}/login${history.location.search}`);
        },
      })
    );
  };

  const handleScroll = e => {
    if (!mainImgRef.current.clientHeight) return;

    if (mainImgRef.current.clientHeight < window.scrollY) {
      headerRef.current.className = "";
    } else {
      headerRef.current.className = "hospital_header";
    }
  };

  useEventListener("scroll", handleScroll, window);

  if (!hospital) return false;
  return (
    <>
      <div>
        <header className="hospital_header" ref={headerRef}>
          <span className="name">{hospital.name}</span>
          {cookies["BABI_TOKEN"] ? (
            <>
              {/* eslint-disable-next-line */}
              <a className="btn_logout" onClick={() => logout()}>
                로그아웃
              </a>
            </>
          ) : (
            <Link to={`${window.location.pathname}/login${history.location.search}`} className="btn_login">
              로그인
            </Link>
          )}
        </header>
        <section className="profile_img" ref={mainImgRef}>
          <img src={hospital.images.data[0].image} alt="병원 메인 프로필 이미지" />
        </section>
        <section className="profile">
          <div className="title">
            <h1>{hospital.name}</h1>
            <p>{hospital.region}</p>
          </div>

          <ul className="categories">
            {hospital.major_categories &&
              hospital.major_categories.split(",").map((v, i) => (
                <li className="major" key={i}>
                  {v}
                </li>
              ))}
            {hospital.categories && hospital.categories.split(",").map((v, i) => <li key={i}>{v}</li>)}
          </ul>

          {hospital.infos && (
            <ul className="confirms scroll_hide">
              {hospital.infos.data.map((v, i) => {
                switch (v.type) {
                  case "cctv":
                    return (
                      <li key={i} className="confirm_01">
                        <p>CCTV설치</p>
                      </li>
                    );
                  case "specialist":
                    return (
                      <li key={i} className="confirm_02">
                        <p>전문의{v.data}명</p>
                      </li>
                    );
                  case "anes":
                    return (
                      <li key={i} className="confirm_03">
                        <p>마취전문의</p>
                      </li>
                    );
                  case "recovery_room":
                    return (
                      <li key={i} className="confirm_04">
                        <p>전담회복실</p>
                      </li>
                    );
                  case "female":
                    return (
                      <li key={i} className="confirm_05">
                        <p>여의사진료</p>
                      </li>
                    );
                  case "night":
                    return (
                      <li key={i} className="confirm_06">
                        <p>야간상담</p>
                      </li>
                    );
                  default:
                    return false;
                }
              })}
            </ul>
          )}
        </section>

        <section className="introduce">
          <h2>병원소개</h2>
          <p>{hospital.description}</p>
        </section>

        {hospital.review_count > 0 && (
          <section className="review" onClick={() => !cookies["BABI_TOKEN"] && showLoginConfirm()}>
            <h2>
              성형톡
              {/* eslint-disable-next-line */}
              <a
                onClick={() =>
                  cookies["BABI_TOKEN"] && history.push(`/hospital/${cid}/review${history.location.search}`)
                }
              >
                모두보기 ({numberWithCommas(hospital.review_count)})
              </a>
            </h2>
            <ul
              className={`img_list scroll_hide ${!cookies["BABI_TOKEN"] ? "blur" : ""}`}
              onClick={e => cookies["BABI_TOKEN"]}
            >
              {hospital.reviews.items.data.map((v, i) => (
                <li
                  key={i}
                  onClick={() =>
                    cookies["BABI_TOKEN"] &&
                    history.push(`/hospital/${cid}/review/detail/${v.id}${history.location.search}`)
                  }
                >
                  <div style={{ background: `url(${v.small_image})` }} />
                </li>
              ))}
            </ul>
            {!cookies["BABI_TOKEN"] && (
              <p className="blur_text">해당 병원의 성형톡은 바비톡 계정으로 로그인 시 확인이 가능합니다.</p>
            )}
          </section>
        )}

        {hospital.event_count > 0 && hospital.events.items.data.length > 0 && (
          <section className="event">
            <h2>
              이벤트
              <Link to={`/hospital/${cid}/event${history.location.search}`}>
                모두보기 ({numberWithCommas(hospital.event_count)})
              </Link>
            </h2>
            <EventList data={hospital.events.items.data} region={hospital.region} />
          </section>
        )}

        {hospital.doctors_count > 0 && (
          <section className="doctor">
            <h2>
              의료진소개
              <Link to={`/hospital/${cid}/doctor${history.location.search}`}>
                모두보기 ({numberWithCommas(hospital.doctors_count)})
              </Link>
            </h2>
            <DoctorList data={hospital.doctors.items} />
          </section>
        )}

        {hospital.youtube_video_count > 0 && (
          <section className="youtube">
            <h2>
              공식 유튜브
              <Link to={`/hospital/${cid}/youtube${history.location.search}`}>
                모두보기 ({numberWithCommas(hospital.youtube_video_count)})
              </Link>
            </h2>
            <YoutubeList data={[{ title: hospital.youtube_title, thumbnails: hospital.youtube_thumbnails }]} />
          </section>
        )}

        <section className="info">
          <h2>병원정보</h2>
          <dl>
            <dt>진료시간</dt>
            <dd>{hospital.visiting_hours}</dd>
          </dl>
          <dl>
            <dt>위치안내</dt>
            <dd>{hospital.location}</dd>
          </dl>

          <h2>시설보기</h2>
          <ul
            className="img_list scroll_hide"
            onClick={e => {
              history.push(`${window.location.pathname}${window.location.search}`);
              dispatch(
                _openImageModal({
                  index: [...e.target.parentElement.childNodes].indexOf(e.target),
                  images: hospital.images.data.slice(1, hospital.images.data.length),
                })
              );
            }}
          >
            {hospital.images.data.map(
              (v, i) => i !== 0 && <li key={i} style={{ background: `url(${v.small_image})` }} />
            )}
          </ul>
        </section>
      </div>
    </>
  );
};

export default Hospital;
