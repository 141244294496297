import React from "react";
import styled from "styled-components";

const LoadingBox = styled.div`
  position: fixed;
  width: 1000%;
  height: 1000%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
`;

const LoadingIcon = styled.div`
  position: fixed;
  top: 50%;
  margin-top: -100px;

  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.4em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
  color: #897dff;
  margin-bottom: 20px;

  @-webkit-keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spinner-border {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Loading = () => {
  return (
    <LoadingBox>
      <LoadingIcon />
    </LoadingBox>
  );
};

export default Loading;
