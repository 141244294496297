import React from "react";

const Confirm = ({ title, msg, buttonText, onAction, onHide }) => {
  return (
    <div className="modal modal-confirm">
      <div className="content">
        <h2 className="title">{title}</h2>
        {msg || "메세지를 입력해주세요."}
      </div>
      <div className="wrap-btn">
        <button className="blur" onClick={() => onHide("cancel")}>
          {buttonText[0] || "아니요"}
        </button>
        <button onClick={() => onAction()}>{buttonText[1] || "예"}</button>
      </div>
    </div>
  );
};

export default Confirm;
