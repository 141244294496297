import React from "react";
import styled from "styled-components";

const EventBox = styled.div`
  padding: 24px 0px 16px;
`;

const EventRow = styled.div`
  display: flex;

  & > * + * {
    margin-left: 12px;
  }

  & + & {
    margin-top: 32px;
  }
  padding-right: 12px;

  font-size: 15px;
  line-height: 16px;
  color: #484760;

  cursor: pointer;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > * + * {
    margin-top: 6px;
  }

  overflow: hidden;
`;

const Ellipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled(Ellipsis)`
  font-weight: bold;
`;

const Description = styled(Ellipsis)`
  font-size: 12px;
  line-height: 14px;
`;

const Infomation = styled.div`
  font-size: 12px;
  line-height: 14px;

  color: #a3a3af;

  span + span::before {
    content: "•";
    margin: 0px 5px;
  }
`;

const PriceRow = styled.div`
  display: flex;
  align-items: flex-end;

  & > * + * {
    margin-left: 8px;
  }

  font-weight: bold;
`;

const DiscountRate = styled.div`
  color: #7264ff;

  &::before {
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url(${require("assets/images/icon/ic-arrow-down.svg").default}) no-repeat;
    margin-right: 4px;
  }
`;

const BadgeRow = styled.div`
  & img {
    width: 45px;
  }

  & img + img {
    margin-left: 4px;
  }
`;

const Event = props => {
  return (
    <EventRow onClick={() => (window.location.href = props.deep_link)}>
      <Image src={props.image} />
      <TextBox>
        <Title>{props.name}</Title>
        <Description>{props.description}</Description>
        <Infomation>
          <span>{props.region}</span>
          <span>{props.hospital_name}</span>
        </Infomation>
        <PriceRow>
          <span>{props.discount_price.toLocaleString()}원</span>
          {props.discount_price < props.price && (
            <DiscountRate>{Number.parseInt(100 - (props.discount_price / props.price) * 100)}%</DiscountRate>
          )}
        </PriceRow>
        {Boolean(props.is_prepayment) && (
          <BadgeRow>
            <img src={require("assets/images/icon/badge-prepayment.png").default} alt="" />
            <img src={require("assets/images/icon/badge-prepayment_vat.png").default} alt="" />
          </BadgeRow>
        )}
      </TextBox>
    </EventRow>
  );
};

const Events = ({ eventList }) => {
  return (
    <EventBox>
      {eventList.map(event => {
        return <Event key={event.id} {...event} />;
      })}
    </EventBox>
  );
};

export default Events;
