import styled from "styled-components";

const Layout = styled.div`
  max-width: 600px;
  min-width: 320px;
  margin: 0px auto;
  padding-bottom: 24px;
`;

export default Layout;
