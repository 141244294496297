import React from "react";
import { Route, Switch } from "react-router-dom";

import List from "pages/exhibition/List";

const ExhibitionRouter = () => {
  return (
    <Switch>
      <Route exact path="/special_exhibitions/:id" component={List} />
    </Switch>
  );
};

export default ExhibitionRouter;
