const initialState = {
  show: false,
};

export function loading_reducer(state = initialState, action) {
  switch (action.type) {
    case "SHOW_LOADING":
      return { show: true };
    case "HIDDEN_LOADING":
      return initialState;
    default:
      return state;
  }
}
